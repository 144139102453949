import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/seo"
import style from "./speaker.module.scss"

const Speaker = ({ data }) => {
  let writing = {}
  for (const i of data.allStrapiWriting.nodes) {
    writing[i.key] = i.writing
  }
  const speakers = data.allStrapiSpeaker.nodes

  return (
    <Layout>
      <SEO title="講者" />
      <HeroImage
        writing={writing.speaker_header || ""}
        portrait="/images/hero.speaker.portrait.svg"
        landscape="/images/hero.speaker.landscape.svg"
      />
      <div className={style.container}>
        <h1>{writing.speaker_title || "講者"}</h1>
        <div style={{ textAlign: "center" }}>
          {writing.speaker_description || ""}
        </div>
        <div className={style.list}>
          {speakers.map(e => (
            <Link
              to={e.speakerPage}
              key={e.id}
              className={[
                style.speaker,
                e.sessions.length > 0 ? style.withSession : "",
              ].join(" ")}
              state={{
                modal: true,
              }}
            >
              <img
                alt={e.name.name}
                src={e.avatar && e.avatar.childImageSharp.resize.src}
              />
              <h2>
                {e.name.name}
                <br />
                {e.name.origional_name}
              </h2>
              <div>
                {e.job && e.job.corp}
                <br />
                {e.job && e.job.job_title}
              </div>

              {e.sessions.length > 0 && (
                <div className={style.session}>
                  <img
                    alt={e.name.name}
                    src={e.avatar && e.avatar.childImageSharp.resize.src}
                  />
                  <div>
                    {new Date(e.sessions[0].start_time).getMonth() + 1}/
                    {new Date(e.sessions[0].start_time).getDate() + " "}
                    {new Date(e.sessions[0].start_time).toLocaleTimeString()}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        e.sessions[0].name &&
                        e.sessions[0].name.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
                    }}
                  ></div>
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SpeakerQuery {
    allStrapiWriting(filter: { key: { glob: "speaker*" } }) {
      nodes {
        key
        writing
      }
    }
    allStrapiSpeaker(
      filter: { site: { id: { eq: 2 } } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        avatar {
          childImageSharp {
            resize(width: 320, height: 320) {
              src
            }
          }
        }
        id
        speakerPage: gatsbyPath(filePath: "/speaker/{StrapiSpeaker.slug}")
        job {
          corp
          id
          job_title
        }
        name {
          id
          name
          origional_name
        }
        order
        sessions {
          start_time
          end_time
          id
          name
          location
          description
          tag {
            tag
          }
        }
        slug
      }
    }
  }
`

export default Speaker
